import { AxiosError } from "axios";
import get from "lodash/get";
import {
  CustomCodeError,
  EC_NO_CARD,
  EC_NO_USER,
  EC_FAILED_FETCH_CONFIG_DATA,
  EC_INVALID_ISSUER_SYS_NAME,
  EC_EMPTY_CONFIG,
  EC_AUTH_NO_AUTHORIZED,
  EC_AUTH_NO_COMPLETED,
  USER_REGISTERED_ERROR_CODE,
  responseErrorCodes,
  EC_WRONG_CODE,
  EC_WRONG_REG_STEP,
} from "../domain/specs/errorCodes";

const UNKNOWN_ERROR_CODE = "UNKNOWN_CODE";

export const isAxiosError = (err: any) => {
  return err instanceof AxiosError || err?.name === "AxiosError";
};

export const isUserAlreadyRegisteredError = (err: any) => {
  const errorCode = get(
    err,
    "response.data.data[0].error_code",
    "unknownText"
  );
  
  return errorCode === USER_REGISTERED_ERROR_CODE;
};

export const isResponseError = (err: any) => {
  return !!err?.response?.data?.data?.length;
};

export const getErrorCodeFromResponse = (err: any): string | null => {
  if (!isResponseError(err)) {
    return null;
  }

  const errorData = err.response.data.data[0];

  if (errorData.error_code !== UNKNOWN_ERROR_CODE) {
    const errorCode =
    responseErrorCodes[errorData.error_code as keyof typeof responseErrorCodes];

    if (errorCode) return errorCode;
  }

  // TODO add possible errors
  switch (errorData.title) {
    case "user not found":
      return EC_NO_USER;
    case "loyalty card not found":
      return EC_NO_CARD;
    case "wrong phone code":
      return EC_WRONG_CODE;
    case "not a next step":
      return EC_WRONG_REG_STEP;
    default:
      return "";
  }
};

export const isNoCardError = (err: any): boolean => {
  if (err?.response?.status !== 422) return false;

  const errorCode = getErrorCodeFromResponse(err);
  return [EC_NO_USER, EC_NO_CARD].includes(errorCode as string);
};

export const isNoActivationCompletedError = (err: any): boolean => {
  return err instanceof CustomCodeError && err.message === EC_AUTH_NO_COMPLETED;
};

export const isNoAuthorizedError = (err: any): boolean => {
  return (
    err?.response?.status === 401 ||
    (err instanceof CustomCodeError && err.message === EC_AUTH_NO_AUTHORIZED)
  );
};

export const isIncorrectRegStepError = (err: any): boolean => {
  if (err?.response?.status !== 422) return false;

  const errorCode = getErrorCodeFromResponse(err);
  return [EC_WRONG_REG_STEP].includes(errorCode as string);
};

export const isIssuerConfigError = (err: any): boolean => {
  return (
    err instanceof CustomCodeError &&
    [
      EC_INVALID_ISSUER_SYS_NAME,
      EC_EMPTY_CONFIG,
      EC_FAILED_FETCH_CONFIG_DATA,
    ].includes(err.message)
  );
};

export const getErrorTextFromResponse = (e: any): string => {
  const errorCode = getErrorCodeFromResponse(e);
  return errorCode || get(e, "response.data.data[0].title", "unknownText");
};
