import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Footer from "./Footer";
import BackButton from "../common/BackButton";
import logoLoyalty from "../../icons/logo-loyalty.svg";

interface IProps {
  title?: string | React.ReactElement;
  backUrl?: string;
  children?: React.ReactNode;
}

const LayoutHeadless: React.FC<IProps> = ({ title, backUrl, children }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{ padding: { xs: "0 2rem" }, marginTop: "2rem" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            mb={title ? 3 : 7}
            position="relative"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            {backUrl && (
              <BackButton
                sx={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                }}
                backUrl={backUrl}
              />
            )}

            <img
              src={logoLoyalty}
              alt="Atomic Loyalty Logo"
              width={72}
              height={72}
              loading="lazy"
            />
          </Box>
          {title && (
            <Typography
              component="h1"
              variant="h5"
              fontSize={20}
              fontWeight="normal"
              align="center"
              mb={3}
            >
              {title}
            </Typography>
          )}

          {children}
        </Box>
      </Container>
      <Footer showLangSelector />
    </Box>
  );
};

export default LayoutHeadless;
