import { decodeBase64 } from "@roketus/web-toolkit";
import { urlErrorCodes } from "../domain/specs/errorCodes";

export const getErrorFromQuery = (
  base64Error: string | null
): string | null => {
  if (!base64Error) return null;

  try {
    const errorString = decodeBase64(base64Error) as string;
    const [errorCode] = errorString.split(":");

    if (errorCode in urlErrorCodes)
      return urlErrorCodes[errorCode as keyof typeof urlErrorCodes];

    return null;
  } catch (e) {
    return null;
  }
};

export const getQueryParamsFromReq = (request: Request): string => {
  const { url: requestUrl } = request;

  const url = new URL(requestUrl);
  const querySearchParams = url.searchParams.toString().length
    ? `?${url.searchParams.toString()}`
    : ``;

  return querySearchParams;
};

export const checkIfCurrentRegStep = (request: Request, stepPath: string): boolean => {
  const { url: requestUrl } = request;

  return requestUrl.includes(stepPath);
};
