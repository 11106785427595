import { lazy, Suspense } from "react";
import {
  type LoaderFunctionArgs,
  createBrowserRouter,
  Outlet,
} from "react-router-dom";
import { withFaroRouterInstrumentation } from "@grafana/faro-react";
import type { PersistentStorage } from "@roketus/web-toolkit";
import type { IIssuerConfigService } from "../../boundary/IIssuerConfigService";
import type { IProfileService } from "../../boundary/IProfileService";
import type { IActionService } from "../../boundary/IActionService";
import type { IAuthService } from "../../boundary/IAuthService";
import { createAuthLoader } from "./loaders/authLoader";
import { createSignupFlowLoader } from "./loaders/signupFlowLoader";
import { createTlgSignupFlowLoader } from "./loaders/tlgSignupFlowLoader";
import { createIssuerLoader } from "./loaders/issuerLoader";
import { createGoaTokenLoader } from "./loaders/goaTokenLoader";
import { createProfileLoader } from "./loaders/profileLoader";
import { createOldRouteRedirectLoader } from "./loaders/oldRouteRedirectLoader";
import { getActionsRoutes, getAnonymousActionsRoutes } from "./actionsRoutes";

import App from "../../view/components/App";
import Loader from "../../view/components/layouts/Loader";
import LayoutHeadless from "../../view/components/layouts/LayoutHeadless";

const LandingPage = lazy(() => import("../../view/pages/LandingPage"));
const ErrorPage = lazy(() => import("../../view/pages/ErrorPage/ErrorPage"));
const SuccessPage = lazy(() => import("../../view/pages/SuccessPage"));
const InfoPage = lazy(() => import("../../view/pages/InfoPage"));
const SignUpPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "SignUpRoutes" */ "../../view/pages/signup/SignUpPage"
    )
);
const SignUpOTPPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "SignUpRoutes" */ "../../view/pages/signup/SignUpOTPPage"
    )
);
const SignUpCheckEmailPage = lazy(
  () => import("../../view/pages/signup/SignUpCheckEmailPage")
);
const SignUpEmailConfirmedPage = lazy(
  () => import("../../view/pages/signup/SignUpEmailConfirmedPage")
);
const SignUpGoogleSuccessPage = lazy(
  () => import("../../view/pages/signup/SignUpGoogleSuccessPage")
);
const SignUpTlgPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpRoutes" */ "../../view/pages/signup/SignUpTlgPage"
    )
);
const SignUpTlgStartPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpRoutes" */
      "../../view/pages/signup/SignUpTlgStartPage"
    )
);
const SignupProfilePage = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpRoutes" */ "../../view/pages/signup/SignupProfilePage"
    )
);
const SignUpBirthPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpRoutes" */ "../../view/pages/signup/SignUpBirthPage"
    )
);
const SignUpGenderPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpRoutes" */ "../../view/pages/signup/SignUpGenderPage"
    )
);
const SignUpWorkplacePage = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpRoutes" */ "../../view/pages/signup/SignUpWorkplacePage"
    )
);
const SignUpAddressPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpRoutes" */ "../../view/pages/signup/SignUpAddressPage"
    )
);

const SignUpVendorIntegrationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SignUpRoutes" */ "../../view/pages/signup/SignUpVendorIntegrationPage"
    )
);
const SignInPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "SignInRoutes" */ "../../view/pages/signin/SignInPage"
    )
);
const ValidateOtpPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "SignUpRoutes" */ "../../view/pages/signin/ValidateOtpPage"
    )
);
const SignInCheckEmailPage = lazy(
  () => import("../../view/pages/signin/SignInCheckEmailPage")
);
const SignInTlgPage = lazy(
  () => import("../../view/pages/signin/SignInTlgPage")
);
const SignInTlgStartPage = lazy(
  () => import("../../view/pages/signin/SignInTlgStartPage")
);

const SurveyActionForm = lazy(
  () =>
    import(
      /* webpackChunkName: "ActionRoutes" */ "../../view/pages/survey/SurveyActionForm"
    )
);
const SurveyAnonymousForm = lazy(
  () =>
    import(
      /* webpackChunkName: "ActionRoutes" */ "../../view/pages/survey/SurveyAnonymousFormPage"
    )
);
const ActionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ActionRoutes" */ "../../view/pages/action/ActionPage"
    )
);
const ActionSuccessPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ActionRoutes" */ "../../view/pages/action/ActionSuccessPage"
    )
);
const ActionErrorPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ActionRoutes" */ "../../view/pages/action/ActionErrorPage"
    )
);
const ProfilePage = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "ProfileRoutes" */ "../../view/pages/profile/ProfilePage"
    )
);
const SettingsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProfileSettingsRoutes" */ "../../view/pages/profile/SettingsPage"
    )
);
const ProfileSettingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProfileSettingsRoutes" */ "../../view/pages/profile/ProfileSettingPage"
    )
);
const ProfileInfoSettingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProfileSettingsRoutes" */ "../../view/pages/profile/ProfileInfoSettingPage"
    )
);
const PhoneSettingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProfileSettingsRoutes" */ "../../view/pages/profile/PhoneSettingPage"
    )
);
const PhoneActivationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProfileSettingsRoutes" */ "../../view/pages/profile/PhoneActivationPage"
    )
);
const EmailSettingPage = lazy(
  () => import("../../view/pages/profile/EmailSettingPage")
);
const EmailVerificationPage = lazy(
  () => import("../../view/pages/profile/EmailVerificationPage")
);

const SuspenseWrapper = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<Loader />}>{children}</Suspense>
);

export const createRouter = ({
  issuerConfigService,
  storage,
  profileService,
  actionService,
  authService,
}: {
  issuerConfigService: IIssuerConfigService;
  storage: PersistentStorage;
  profileService: IProfileService;
  actionService: IActionService;
  authService: IAuthService;
}) => {
  const profileLoaders = async (props: LoaderFunctionArgs) => {
    await createIssuerLoader(issuerConfigService)(props);
    return await createProfileLoader(
      issuerConfigService,
      profileService,
      authService
    )(props);
  };

  const signupLoaders = async (props: LoaderFunctionArgs) => {
    await createIssuerLoader(issuerConfigService)(props);
    return await createAuthLoader(
      authService,
      issuerConfigService,
      true,
      true
    )(props);
  };

  const signupFlowLoaders = async (props: LoaderFunctionArgs) => {
    await createIssuerLoader(issuerConfigService)(props);
    return await createSignupFlowLoader(
      authService,
      issuerConfigService
    )(props);
  };

  const tlgSignupFlowLoaders = async (props: LoaderFunctionArgs) => {
    await createIssuerLoader(issuerConfigService)(props);
    return await createTlgSignupFlowLoader(
      authService,
      issuerConfigService
    )(props);
  };

  const browserRouter = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: (
        <SuspenseWrapper>
          <ErrorPage />
        </SuspenseWrapper>
      ),
      children: [
        {
          index: true,
          loader: createIssuerLoader(issuerConfigService, false),
        },

        {
          path: ":issuerSysName",
          children: [
            {
              index: true,
              element: (
                <SuspenseWrapper>
                  <ProfilePage />
                </SuspenseWrapper>
              ),
              loader: profileLoaders,
            },
            {
              path: "settings",
              loader: profileLoaders,
              children: [
                {
                  index: true,
                  element: (
                    <SuspenseWrapper>
                      <SettingsPage />
                    </SuspenseWrapper>
                  ),
                },
                {
                  path: "profile",
                  children: [
                    {
                      index: true,
                      element: (
                        <SuspenseWrapper>
                          <ProfileSettingPage />
                        </SuspenseWrapper>
                      ),
                    },
                    {
                      path: ":form",
                      element: (
                        <SuspenseWrapper>
                          <ProfileInfoSettingPage />
                        </SuspenseWrapper>
                      ),
                    },
                  ],
                },
                {
                  path: "phone",
                  children: [
                    {
                      index: true,
                      element: (
                        <SuspenseWrapper>
                          <PhoneSettingPage />
                        </SuspenseWrapper>
                      ),
                    },
                    {
                      path: "activation",
                      element: (
                        <SuspenseWrapper>
                          <PhoneActivationPage />
                        </SuspenseWrapper>
                      ),
                    },
                  ],
                },
                {
                  path: "email",
                  children: [
                    {
                      index: true,
                      element: (
                        <SuspenseWrapper>
                          <EmailSettingPage />
                        </SuspenseWrapper>
                      ),
                    },
                    {
                      path: "verification",
                      element: (
                        <SuspenseWrapper>
                          <EmailVerificationPage />
                        </SuspenseWrapper>
                      ),
                    },
                  ],
                },
              ],
            },

            // SIGNIN routes
            {
              path: "signin",
              children: [
                {
                  index: true,
                  element: (
                    <SuspenseWrapper>
                      <SignInPage />
                    </SuspenseWrapper>
                  ),
                  loader: async (props) => {
                    await createIssuerLoader(issuerConfigService)(props);
                    return await createAuthLoader(
                      authService,
                      issuerConfigService,
                      true
                    )(props);
                  },
                },
                {
                  path: "check-email",
                  element: (
                    <SuspenseWrapper>
                      <SignInCheckEmailPage />
                    </SuspenseWrapper>
                  ),
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "validate-otp",
                  element: (
                    <SuspenseWrapper>
                      <ValidateOtpPage />
                    </SuspenseWrapper>
                  ),
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "goa-success",
                  loader: async (props) => {
                    await createIssuerLoader(issuerConfigService)(props);
                    return await createGoaTokenLoader(
                      issuerConfigService,
                      authService,
                      actionService
                    )(props);
                  },
                },
                {
                  path: "telegram",
                  loader: createIssuerLoader(issuerConfigService),
                  children: [
                    {
                      index: true,
                      element: (
                        <SuspenseWrapper>
                          <SignInTlgStartPage />
                        </SuspenseWrapper>
                      ),
                    },
                    {
                      path: "otp",
                      element: (
                        <SuspenseWrapper>
                          <SignInTlgPage />
                        </SuspenseWrapper>
                      ),
                    },
                  ],
                },
              ],
            },
            {
              path: "signin", // smart-link routes with loader
              loader: async (props) => {
                await createIssuerLoader(issuerConfigService)(props);
                return await createAuthLoader(
                  authService,
                  issuerConfigService
                )(props);
              },
              children: [...getActionsRoutes(actionService)],
            },

            // SIGNUP routes
            {
              path: "signup",
              children: [
                {
                  index: true,
                  element: (
                    <SuspenseWrapper>
                      <SignUpPage />
                    </SuspenseWrapper>
                  ),
                  loader: signupLoaders,
                },
                {
                  path: "check-email",
                  element: (
                    <SuspenseWrapper>
                      <SignUpCheckEmailPage />
                    </SuspenseWrapper>
                  ),
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "email-success",
                  element: (
                    <SuspenseWrapper>
                      <SignUpEmailConfirmedPage />
                    </SuspenseWrapper>
                  ),
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "validate-otp",
                  element: (
                    <SuspenseWrapper>
                      <SignUpOTPPage />
                    </SuspenseWrapper>
                  ),
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "goa-success",
                  element: (
                    <SuspenseWrapper>
                      <SignUpGoogleSuccessPage />
                    </SuspenseWrapper>
                  ),
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "telegram",
                  children: [
                    {
                      index: true,
                      element: (
                        <SuspenseWrapper>
                          <SignUpTlgStartPage />
                        </SuspenseWrapper>
                      ),
                      loader: createIssuerLoader(issuerConfigService),
                    },
                    {
                      path: "otp",
                      element: (
                        <SuspenseWrapper>
                          <SignUpTlgPage />
                        </SuspenseWrapper>
                      ),
                      loader: tlgSignupFlowLoaders,
                    },
                  ],
                },
                {
                  path: "profile",
                  element: (
                    <LayoutHeadless>
                      <Outlet />
                    </LayoutHeadless>
                  ),
                  children: [
                    {
                      index: true,
                      element: (
                        <SuspenseWrapper>
                          <SignupProfilePage />
                        </SuspenseWrapper>
                      ),
                      loader: signupFlowLoaders,
                    },
                    {
                      path: "birth",
                      element: (
                        <SuspenseWrapper>
                          <SignUpBirthPage />
                        </SuspenseWrapper>
                      ),
                      loader: signupFlowLoaders,
                    },
                    {
                      path: "gender",
                      element: (
                        <SuspenseWrapper>
                          <SignUpGenderPage />
                        </SuspenseWrapper>
                      ),
                      loader: signupFlowLoaders,
                    },
                    {
                      path: "workplace",
                      element: (
                        <SuspenseWrapper>
                          <SignUpWorkplacePage />
                        </SuspenseWrapper>
                      ),
                      loader: signupFlowLoaders,
                    },
                    {
                      path: "address",
                      element: (
                        <SuspenseWrapper>
                          <SignUpAddressPage />
                        </SuspenseWrapper>
                      ),
                      loader: signupFlowLoaders,
                    },
                  ],
                },
                {
                  path: "vendor-integration",
                  element: (
                    <SuspenseWrapper>
                      <SignUpVendorIntegrationPage />
                    </SuspenseWrapper>
                  ),
                  loader: signupFlowLoaders,
                },
              ],
            },
            // smart-link routes with loader
            {
              path: "signup",
              loader: async (props) => {
                await createIssuerLoader(issuerConfigService)(props);
                return await createAuthLoader(
                  authService,
                  issuerConfigService
                )(props);
              },
              children: [...getActionsRoutes(actionService)],
            },

            {
              path: "survey",
              element: (
                <SuspenseWrapper>
                  <SurveyActionForm />
                </SuspenseWrapper>
              ),
              loader: async (props) => {
                await createIssuerLoader(issuerConfigService)(props);
                return await createAuthLoader(
                  authService,
                  issuerConfigService
                )(props);
              },
            },
          ],
        },

        // Anonymous routes with issuer loader
        {
          path: ":issuerSysName",
          loader: createIssuerLoader(issuerConfigService),
          children: [
            {
              path: "landing",
              element: (
                <SuspenseWrapper>
                  <LandingPage />
                </SuspenseWrapper>
              ),
            },
            {
              path: "free-survey",
              element: (
                <SuspenseWrapper>
                  <SurveyAnonymousForm />
                </SuspenseWrapper>
              ),
            },
            {
              path: "success",
              element: (
                <SuspenseWrapper>
                  <SuccessPage />
                </SuspenseWrapper>
              ),
            },
            {
              path: "info",
              element: (
                <SuspenseWrapper>
                  <InfoPage />
                </SuspenseWrapper>
              ),
            },

            {
              path: "action",
              children: [
                {
                  index: true,
                  element: (
                    <SuspenseWrapper>
                      <ActionPage />
                    </SuspenseWrapper>
                  ),
                },
                {
                  path: "success",
                  element: (
                    <SuspenseWrapper>
                      <ActionSuccessPage />
                    </SuspenseWrapper>
                  ),
                },
                {
                  path: "info",
                  element: (
                    <SuspenseWrapper>
                      <ActionErrorPage />
                    </SuspenseWrapper>
                  ),
                },
              ],
            },
            // Anonymous smart-link routes with loader
            ...getAnonymousActionsRoutes(actionService),
          ],
        },

        // Routes for redirect from old pathes
        {
          path: "signin/:issuerSysName/*",
          loader: createOldRouteRedirectLoader(issuerConfigService),
        },
        {
          path: "signup/:issuerSysName/*",
          loader: createOldRouteRedirectLoader(issuerConfigService),
        },
      ],
    },
  ]);

  return withFaroRouterInstrumentation(browserRouter);
};

// TODO: fix custom Router interface vs React Router
// export const createRouter = withFaroRouterInstrumentation(reactBrowserRouter as Router);
