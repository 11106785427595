import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { IAuthService } from "../../../boundary/IAuthService";
import { IIssuerConfigService } from "../../../boundary/IIssuerConfigService";
import {
  isNoActivationCompletedError,
  isNoAuthorizedError,
  isNoCardError,
} from "../../../utils/response-errors";
import {
  getNextRegStepPath,
  isInvalidIssuerSysName,
} from "../../../utils/registration";
import {
  CustomCodeError,
  EC_INVALID_ISSUER_SYS_NAME,
} from "../../../domain/specs/errorCodes";
import { isInAppBrowser } from "../../../utils/utils";
import { getQueryParamsFromReq } from "../../../utils/url-params";

export const createAuthLoader =
  (
    authService: IAuthService,
    issuerConfigService: IIssuerConfigService,
    redirectIfAuthenticated?: boolean,
    checkTokensWithoutReq?: boolean
  ) =>
  async (props: LoaderFunctionArgs) => {
    if (isInAppBrowser()) {
      return null;
    }

    const issuerSysName =
      props.params.issuerSysName || issuerConfigService.getIssuer();

    try {
      if (isInvalidIssuerSysName(issuerSysName))
        throw new CustomCodeError(EC_INVALID_ISSUER_SYS_NAME);

      await authService.invokeIsAuth(checkTokensWithoutReq);

      if (redirectIfAuthenticated) {
        return redirect(`/${issuerSysName}`);
      }

      return null;
    } catch (e: any) {
      if (isInvalidIssuerSysName(issuerSysName))
        throw new CustomCodeError(EC_INVALID_ISSUER_SYS_NAME);

      if (isNoAuthorizedError(e)) {
        return null;
      }

      const querySearchParams = getQueryParamsFromReq(props.request);

      if (isNoActivationCompletedError(e)) {
        const stepPath = getNextRegStepPath(e.extraInfo?.nextStep, true);
        return redirect(`/${issuerSysName}${stepPath}${querySearchParams}`);
      }

      if (isNoCardError(e)) {
        authService.removeTokens();
        return redirect(`/${issuerSysName}/signup${querySearchParams}`);
      }

      throw e;
    }
  };
