import { StateSubject } from "@roketus/web-toolkit";
import { ISDK } from "@roketus/loyalty-end-user-js-sdk";
import type {
  IRouterService,
  IRouterServiceData,
} from "../../boundary/IRouterService";
import {
  getActionService,
  getIssuerConfigService,
  getProfileService,
  getRouter,
} from "../../diContainer/getDependencies";
import {
  ACTION_DISPATCH_SEARCH_PARAM,
  ActionsPaths,
} from "../router/actionsRoutes";
import { getNextRegStepPath } from "../../utils/registration";

const stateMachine = new StateSubject<IRouterServiceData>({});

export const init = (sdk: ISDK): IRouterService => {
  const navigateToIssuerPath: IRouterService["navigateToIssuerPath"] = (
    path,
    state,
    replace,
  ) => {
    const router = getRouter();
    const issuerConfigService = getIssuerConfigService();

    const issuerSysName = issuerConfigService.getIssuer(sdk);

    router.navigate(`/${issuerSysName}${path}`, { state, replace });
  };

  const navigateToPathWithActionIfExist: IRouterService["navigateToPathWithActionIfExist"] =
    (path, state) => {
      const actionService = getActionService();
      actionService.restoreActionFromStorage();
      actionService.removeActionFromStorage();

      const { dispatchKey, actionType } = actionService.data$.value;
      const actionPath =
        actionType && dispatchKey
          ? `/${actionType}?${ACTION_DISPATCH_SEARCH_PARAM}=${dispatchKey}`
          : "";

      navigateToIssuerPath(`${path}${actionPath}`, { state });
    };

  const navigateToInitialPage: IRouterService["navigateToInitialPage"] = () => {
    const actionService = getActionService();

    const { dispatchKey, actionType } = actionService.data$.value;

    let path = "/";

    if (dispatchKey) {
      path = actionType === ActionsPaths.Survey ? "/survey" : "/action";
    }

    navigateToIssuerPath(path); // {}, true
  };

  const navigateToNextRegStep: IRouterService["navigateToNextRegStep"] = (
    stepInfo
  ) => {
    if (stepInfo.completed) {
      navigateToInitialPage();
      return;
    }

    const stepPath = getNextRegStepPath(stepInfo.nextStep);

    navigateToIssuerPath(stepPath); // {}, true
  };

  const getReferralLink: IRouterService["getReferralLink"] = () => {
    const profileService = getProfileService();
    const issuerConfigService = getIssuerConfigService();

    const issuerSysName = issuerConfigService.getIssuer(sdk);
    const refCode = profileService.data$.value.profile?.refCode;
    const shop = profileService.data$.value.profile?.shops?.[0];

    if (issuerSysName && refCode) {
      let query = `?refCode=${refCode}`;

      if (shop) {
        query += `&shop=${shop}`;
      }

      return `${window.location.origin}/${issuerSysName}/signup${query}`;
    }

    return null;
  };

  return {
    data$: stateMachine.state$,
    navigateToIssuerPath,
    navigateToPathWithActionIfExist,
    navigateToNextRegStep,
    navigateToInitialPage,
    getReferralLink,
  };
};
