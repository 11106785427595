import { LoaderFunctionArgs, redirect } from "react-router-dom";
import type { IIssuerConfigService } from "../../../boundary/IIssuerConfigService";
import {
  CustomCodeError,
  EC_INVALID_ISSUER_SYS_NAME,
} from "../../../domain/specs/errorCodes";
import { isInvalidIssuerSysName } from "../../../utils/registration";

export const createIssuerLoader =
  (issuerConfigService: IIssuerConfigService, isIssuerPath = true) =>
  async (props: LoaderFunctionArgs) => {
    // redirect from 'unknown' issuer (can be redirected from BE with 'unknown')
    if (props.params.issuerSysName === "unknown") {
      const issuerSysName = issuerConfigService.getIssuer();

      if (isInvalidIssuerSysName(issuerSysName))
        throw new CustomCodeError(EC_INVALID_ISSUER_SYS_NAME);

      const requestUrl = props.request.url;
      const issuerUrl = requestUrl.replace("/unknown", `/${issuerSysName}`);

      return redirect(issuerUrl);
    }

    let issuerSysName =
      props.params.issuerSysName || issuerConfigService.getIssuer();

    try {
      if (isInvalidIssuerSysName(issuerSysName))
        throw new CustomCodeError(EC_INVALID_ISSUER_SYS_NAME);

      const lowerCaseToken = issuerSysName!.toLocaleLowerCase();

      issuerConfigService.setIssuer(lowerCaseToken);

      if (!isIssuerPath) return redirect(`/${lowerCaseToken}`);

      if (
        issuerConfigService.data$.value.loading ||
        issuerConfigService.data$.value.isLoaded
      ) {
        return null;
      }

      await issuerConfigService.fetchConfig(lowerCaseToken);

      return null;
    } catch (e: any) {
      throw e;
    }
  };
