import { type FC, type MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, TextField, styled } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import LinkIcon from "@mui/icons-material/Link";
import CopyIcon from "@mui/icons-material/ContentCopy";
import PopoverAutoClosing from "./PopoverAutoClosing";
import { copyText } from "../../../utils/utils";

interface Props {
  type?: "input" | "link";
  url?: string | null;
  handleSuccess?: () => void;
  handleError?: (e: any) => void;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  fontSize: "16px",
  "& .MuiInputBase-root": {
    border: 0,
    borderRadius: 16,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  input: {
    flex: "1 1 0",
    fontSize: "16px",
    borderRadius: 16,
  },
}));

const CopyLink: FC<Props> = ({
  type = "link",
  url = "",
  handleSuccess,
  handleError,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "common" });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleCopyClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (url) {
      try {
        copyText(url);

        setAnchorEl(e.currentTarget);

        handleSuccess && handleSuccess();
      } catch (e) {
        handleError && handleError(e);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {type === "input" ? (
        <StyledTextField
          id="copy-link-input"
          name="copy-link-input"
          value={url}
          fullWidth
          variant="outlined"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
            input: {
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start" sx={{ height: "auto" }}>
                  <LinkIcon
                    sx={(theme) => ({
                      color: theme.palette.background.default,
                    })}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ height: "auto" }}>
                  <CopyIcon
                    sx={(theme) => ({
                      color: theme.palette.background.default,
                    })}
                  />
                  <Button
                    variant="text"
                    sx={(theme) => ({
                      whiteSpace: "nowrap",
                      ml: "4px",
                      color: theme.palette.background.default,
                      fontSize: "16px",
                    })}
                    onClick={handleCopyClick}
                  >
                    {t("copy")}
                  </Button>
                </InputAdornment>
              ),
            },
          }}
        />
      ) : (
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            display: "inline",
          }}
          role="link"
          onClick={handleCopyClick}
        >
          {url}
        </span>
      )}

      <PopoverAutoClosing anchorEl={anchorEl} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {t("copiedMessage")}
        </Alert>
      </PopoverAutoClosing>
    </>
  );
};

export default CopyLink;
