import {
  // IGetBonusesResponseAttributes,
  IGetCardInfoResponseAttributes,
  IPassDTO,
} from "@roketus/loyalty-end-user-js-sdk";
import { ICardInfo } from "../../boundary/IProfileService";
import { toBarcodeEntity } from "./toBarcodeEntity";

export const toCardInfoEntity = (
  card: IGetCardInfoResponseAttributes,
  passData: IPassDTO
  // bonusesData: IGetBonusesResponseAttributes
): ICardInfo | null => {
  if (!card) return null;

  const barcodeInfo = toBarcodeEntity(passData, card?.CardNumber);
  const companyName = passData?.PassBack?.find(
    (field) => field.Key === "company_name"
  )?.Value;
  const bonusField = [
    ...passData.PassHeader,
    ...passData.PassPrimary,
    ...passData.PassSecondary,
    ...passData.PassAuxiliary,
    ...passData?.PassBack,
  ].find(field => field.Key === "bonus");
  const bonusBalance = bonusField ? bonusField.Value : 0;

  return {
    barcodeInfo,
    void: passData?.PassInfo?.VoidPass,
    fullName: card?.NameOnCard,
    discount: card?.BonusPercent,
    cardNumber: card?.CardNumber,
    level: card?.Level,
    bonusBalance: Number(bonusBalance) ? bonusBalance : 0,
    // bonusesData?.totalActive.UAH ||
    // bonusesData?.totalActive.USD ||
    // bonusesData?.totalActive.EUR ||
    // 0,
    organizationName: passData?.PassInfo?.OrganizationName,
    description: passData?.PassInfo?.Description,
    logoId: passData?.PassStyle?.LogoUrl,
    logoText: passData?.PassInfo?.LogoText,
    stripId: passData?.PassStyle?.StripUrl,
    thumbId: passData?.PassStyle?.ThumbnailUrl,
    backgroundColor: passData?.PassStyle?.Background,
    textColor: passData?.PassStyle?.Foreground,
    labelColor: passData?.PassStyle?.Label,
    companyName,
  };
};
