import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { IAuthService } from "../../../boundary/IAuthService";
import { IIssuerConfigService } from "../../../boundary/IIssuerConfigService";
import {
  isNoActivationCompletedError,
  isNoAuthorizedError,
  isNoCardError,
} from "../../../utils/response-errors";
import {
  getNextRegStepPath,
  isInvalidIssuerSysName,
} from "../../../utils/registration";
import {
  CustomCodeError,
  EC_INVALID_ISSUER_SYS_NAME,
} from "../../../domain/specs/errorCodes";
import { isInAppBrowser } from "../../../utils/utils";
import { checkIfCurrentRegStep, getQueryParamsFromReq } from "../../../utils/url-params";

export const createSignupFlowLoader =
  (
    authService: IAuthService,
    issuerConfigService: IIssuerConfigService,
  ) =>
  async (props: LoaderFunctionArgs) => {
    if (isInAppBrowser()) {
      return null;
    }

    const issuerSysName =
      props.params.issuerSysName || issuerConfigService.getIssuer();

    try {
      if (isInvalidIssuerSysName(issuerSysName))
        throw new CustomCodeError(EC_INVALID_ISSUER_SYS_NAME);

      await authService.invokeIsAuth(false, true);

      return redirect(`/${issuerSysName}`);
    } catch (e: any) {
      if (isInvalidIssuerSysName(issuerSysName))
        throw new CustomCodeError(EC_INVALID_ISSUER_SYS_NAME);

      const querySearchParams = getQueryParamsFromReq(props.request);

      if (isNoAuthorizedError(e)) {
				return redirect(`/${issuerSysName}/signup${querySearchParams}`);
      }

      if (isNoActivationCompletedError(e)) {
        const stepPath = getNextRegStepPath(e.extraInfo?.nextStep, true);

				if (checkIfCurrentRegStep(props.request, stepPath)) {
					return null;
				}

        return redirect(`/${issuerSysName}${stepPath}${querySearchParams}`);
      }

      if (isNoCardError(e)) {
        authService.removeTokens();
        return redirect(`/${issuerSysName}/signup${querySearchParams}`);
      }

      throw e;
    }
  };
