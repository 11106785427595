import { StateSubject } from "@roketus/web-toolkit";
import { format } from "date-fns";
import type {
  ISignupService,
  ISignupServiceData,
} from "../../boundary/ISignUpService";
import {
  getActionService,
  getAuthService,
  getIssuerConfigService,
  getRouterService,
  getSdk,
  getSigninService,
} from "../../diContainer/getDependencies";
import { EC_WRONG_CODE } from "../../domain/specs/errorCodes";
import { pushErrorToFaro, pushLogToFaro } from "../../utils/faroLogs";
import {
  getErrorTextFromResponse,
  isAxiosError,
  isIncorrectRegStepError,
  isUserAlreadyRegisteredError,
} from "../../utils/response-errors";
import type { IRegStepResponseAttributes } from "@roketus/loyalty-end-user-js-sdk";
import { AuthMethod } from "../../domain/specs/authMethods";
import { RegistrationType } from "../../domain/specs/registrationModes";
import type { TSetFormFieldError } from "../../boundary/forms/TSetFormFieldError";
import type { IPhoneFormData } from "../../boundary/IPhoneFormData";
import type { IEmailFormData } from "../../boundary/IEmailFormData";
import { getRegMode } from "../../utils/registration";
import { BIRTH_DATE_FORMAT } from "../constants";

export const stateMachine = new StateSubject<ISignupServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
  stepData: null,
  signupMethod: AuthMethod.Phone,
  activationCode: undefined,
});

export const signupService: ISignupService = {
  setPhoneState: (phoneNumber) => {
    stateMachine.setState({
      phoneNumber,
    });
  },

  setEmailState: (email) => {
    stateMachine.setState({
      email,
    });
  },

  setSignupMethod: (signupMethod: AuthMethod) => {
    stateMachine.setState({
      signupMethod,
    });
  },

  signupByPhone: async (phoneNumber, params, setError) => {
    pushLogToFaro("Sign up: set phone", params as Record<string, string>);

    const sdk = getSdk();
    const routerService = getRouterService();
    const authService = getAuthService();

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    try {
      const regMode = getRegMode(AuthMethod.Phone);

      const { data } = await sdk.signup.setPhone(
        regMode,
        phoneNumber.replace(/\s+/g, ""),
        params
      ).result;

      const token = data.user?.token;
      const refreshToken = data.user?.refresh;

      if (!!token) {
        authService.setTokens(token, refreshToken);
      }

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        phoneNumber,
        stepData: data,
      });

      pushLogToFaro("Success: set phone on sign up");

      routerService.navigateToNextRegStep(data);
    } catch (e) {
      pushErrorToFaro(e, {
        action: "signupByPhone",
        message: "Error: failed to set phone on sign up",
      });

      if (isAxiosError(e)) {
        const errorText = getErrorTextFromResponse(e);

        if (isUserAlreadyRegisteredError(e)) {
          const signInService = getSigninService();

          signInService.setPhoneState(phoneNumber);

          stateMachine.setState({
            loading: false,
            isLoaded: true,
            failed: false,
          });

          pushLogToFaro("Sign up: redirecting to signin flow", {
            issuer: String(getIssuerConfigService().getIssuer()),
          });

          routerService.navigateToIssuerPath("/signin/validate-otp");

          return;
        }

        setError && setError("phone", errorText);
      }

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  },

  signupByEmail: async (email, params, setError) => {
    pushLogToFaro("Sign up: set email");

    const sdk = getSdk();
    const routerService = getRouterService();
    const authService = getAuthService();

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    try {
      const regMode = getRegMode(AuthMethod.Email);

      const { data } = await sdk.signup.setEmail(
        regMode,
        email
        // params
      ).result;
      pushLogToFaro("Success: set email on sign up");

      const { data: termsData } = await sdk.signup.regWithTerms(regMode, true)
        .result;
      pushLogToFaro("Success: registration with terms");

      const token = data.user?.token;
      const refreshToken = data.user?.refresh;

      if (!!token) {
        authService.setTokens(token, refreshToken);
      }

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        email,
        stepData: termsData,
      });

      // routerService.navigateToNextRegStep(data);
    } catch (e) {
      pushErrorToFaro(e, {
        action: "signupByEmail",
        message: "Error: failed to set email on sign up",
      });

      if (isAxiosError(e)) {
        const errorText = getErrorTextFromResponse(e);

        if (isUserAlreadyRegisteredError(e)) {
          const signInService = getSigninService();

          signInService.setEmailState(email);

          stateMachine.setState({
            loading: false,
            isLoaded: true,
            failed: false,
          });

          pushLogToFaro("Sign up: redirecting to signin flow", {
            issuer: String(getIssuerConfigService().getIssuer()),
          });

          routerService.navigateToIssuerPath("/signin/validate-otp");

          return;
        }

        setError && setError("email", errorText);
      }

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  },

  signupByGoogle: async (params) => {
    pushLogToFaro("Sign up: by Google");

    const sdk = getSdk();
    const authService = getAuthService();

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    try {
      const { data: startData } = await sdk.signup.regWithTerms(
        RegistrationType.google,
        true,
        params
      ).result;

      const token = startData.user?.token;
      const refreshToken = startData.user?.refresh;

      if (!!token) {
        authService.setTokens(token, refreshToken);
      }

      const goaResult = await sdk.signup.regWithGoa().result;

      goaResult.data.url && window.open(goaResult.data.url, "_self");

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        stepData: startData,
      });

      pushLogToFaro("Success: sign up by Google");
    } catch (e) {
      pushErrorToFaro(e, {
        action: "signupByGoogle",
        message: "Error: failed to sign up by Google",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  },

  signupByTlg: async (payload, params, setError) => {
    pushLogToFaro("Sign up: by Telegram", params as Record<string, string>);

    const sdk = getSdk();
    const routerService = getRouterService();
    const authService = getAuthService();

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    try {
      const regMode = getRegMode(AuthMethod.Telegram);

      const { data: startData } = await sdk.signup.regWithTlg(
        regMode,
        {
          phone: payload.phone.replace(/\s+/g, ""),
          code: payload.code,
        },
        params
      ).result;

      const token = startData.user?.token;
      const refreshToken = startData.user?.refresh;

      if (!!token) {
        authService.setTokens(token, refreshToken);
      }

      const { data: nextStepData } = await sdk.signup.regWithTerms(
        regMode,
        true
      ).result;

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        stepData: nextStepData,
        signupMethod: AuthMethod.Telegram,
      });

      pushLogToFaro("Success: sign up by Telegram");

      routerService.navigateToNextRegStep(nextStepData);
    } catch (e) {
      pushErrorToFaro(e, {
        action: "signupByTlg",
        message: "Error: failed to sign up by Telegram",
      });

      const errorTextKey = getErrorTextFromResponse(e);
      if (setError) {
        const fieldKey = errorTextKey === EC_WRONG_CODE ? "code" : "phone";
        setError(fieldKey, errorTextKey);
      }

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  },

  signupByCurrentMethod: async (phoneOrEmail, params, setError) => {
    const signupMethod = stateMachine.state$.value.signupMethod;

    if (signupMethod === AuthMethod.Email) {
      await signupService.signupByEmail(
        phoneOrEmail,
        params,
        setError as TSetFormFieldError<IEmailFormData>
      );
    } else {
      await signupService.signupByPhone(
        phoneOrEmail,
        params,
        setError as TSetFormFieldError<IPhoneFormData>
      );
    }
  },

  setCode: async (code, setError) => {
    pushLogToFaro("Sign up: set OTP");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();
    const routerService = getRouterService();

    try {
      const regMode = getRegMode(AuthMethod.Phone);

      await sdk.signup.phoneActivation(regMode, parseInt(code, 10)).result;
      pushLogToFaro("Success: phone activation on signup");

      const { data } = await sdk.signup.regWithTerms(regMode, true).result;
      pushLogToFaro("Success: registration with terms");

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        stepData: data,
        activationCode: undefined,
      });

      routerService.navigateToNextRegStep(data);
    } catch (e) {
      if (isIncorrectRegStepError(e) && stateMachine.state$.value.stepData) {
        routerService.navigateToNextRegStep(stateMachine.state$.value.stepData);
      }

      pushErrorToFaro(e, {
        action: "setCode",
        message: "Error: failed to set OTP on signup",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      const errorTextKey = getErrorTextFromResponse(e);

      setError && setError("otp", errorTextKey);
    }
  },

  resendCode: async () => {
    pushLogToFaro("Sign up: resend OTP on signup");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();

    try {
      const { data } = await sdk.phone.resendActivationCode().result;

      pushLogToFaro("Success: resend OTP on signup");

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        activationCode: data.code ? `${data.code}` : undefined ,
      });
    } catch (e) {
      pushErrorToFaro(e, {
        action: "resendCode",
        message: "Error: failed to resend OTP on signup",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  },

  goaActivation: async () => {
    pushLogToFaro("Sign up: activate profile registered with google");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
      signupMethod: AuthMethod.Google,
    });

    const sdk = getSdk();
    const actionService = getActionService();
    const routerService = getRouterService();

    try {
      actionService.restoreActionFromStorage();
      actionService.removeActionFromStorage();

      const { data } = await sdk.signup.goaActivation(
        RegistrationType.google,
        true
      ).result;

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        stepData: data,
      });
    } catch (e: any) {
      pushErrorToFaro(e, {
        action: "goaActivation",
        message: "Error: failed to activate profile registered with google",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  },

  setProfile: async (formData) => {
    pushLogToFaro("Sign up: set profile");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();
    const routerService = getRouterService();
    const action = getActionService();
    const authService = getAuthService();

    try {
      const { signupMethod } = stateMachine.state$.value || {};
      const regMode = getRegMode(signupMethod);

      const { data } = await sdk.signup.setProfile(regMode, formData).result;

      // TODO remove on BE or add UI
      if (regMode === RegistrationType.google) {
        await signupService.skipStep("birth");
      }

      if (data.completed) {
        authService.setIsAuthenticated();
      }

      const { actionType } = action.data$.value;
      pushLogToFaro("Success: set profile", { actionType });

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        stepData: data,
        // signupMethod: AuthMethod.Phone, // TODO remove hardcode - set on last step
      });
    } catch (e) {
      pushErrorToFaro(e, {
        action: "setProfile",
        message: "Error: failed to set profile",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  },

  setBirth: async (formData, setError) => {
    pushLogToFaro("Sign up: set birth");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();
    const action = getActionService();
    const routerService = getRouterService();
    const authService = getAuthService();

    try {
      const { signupMethod } = stateMachine.state$.value || {};
      const regMode = getRegMode(signupMethod);

      const payload = {
        birth: format(formData.birth as Date, BIRTH_DATE_FORMAT),
      };

      const { data } = await sdk.signup.setBirth(regMode, payload).result;

      if (data.completed) {
        authService.setIsAuthenticated();
      }

      const { actionType } = action.data$.value;
      pushLogToFaro("Success: set birth", { actionType });

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        stepData: data,
        // signupMethod: AuthMethod.Phone, // TODO remove hardcode - set on last step
      });
    } catch (e) {
      pushErrorToFaro(e, {
        action: "setProfile",
        message: "Error: failed to set birth",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      const errorTextKey = getErrorTextFromResponse(e);

      setError && setError("birth", errorTextKey);
    }
  },

  setGender: async (formData, setError) => {
    pushLogToFaro("Sign up: set gender");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();
    const action = getActionService();
    const routerService = getRouterService();
    const authService = getAuthService();

    try {
      const { signupMethod } = stateMachine.state$.value || {};
      const regMode = getRegMode(signupMethod);

      const { data } = await sdk.signup.setGender(regMode, formData).result;

      if (data.completed) {
        authService.setIsAuthenticated();
      }

      const { actionType } = action.data$.value;
      pushLogToFaro("Success: set gender", { actionType });

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        stepData: data,
        // signupMethod: AuthMethod.Phone, // TODO remove hardcode - set on last step
      });
    } catch (e) {
      pushErrorToFaro(e, {
        action: "setProfile",
        message: "Error: failed to set gender",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      const errorTextKey = getErrorTextFromResponse(e);

      setError && setError("gender", errorTextKey);
    }
  },

  setWorkplace: async (formData, setError) => {
    pushLogToFaro("Sign up: set workplace");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();
    const action = getActionService();
    const routerService = getRouterService();
    const authService = getAuthService();

    try {
      const { signupMethod } = stateMachine.state$.value || {};
      const regMode = getRegMode(signupMethod);

      const { data } = await sdk.signup.setWorkplace(regMode, formData).result;

      if (data.completed) {
        authService.setIsAuthenticated();
      }

      const { actionType } = action.data$.value;
      pushLogToFaro("Success: set workplace", { actionType });

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        stepData: data,
        // signupMethod: AuthMethod.Phone, // TODO remove hardcode - set on last step
      });
    } catch (e) {
      pushErrorToFaro(e, {
        action: "setProfile",
        message: "Error: failed to set workplace",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      const errorTextKey = getErrorTextFromResponse(e);

      setError && setError("workplace", errorTextKey);
    }
  },

  setAddress: async (formData, setError) => {
    pushLogToFaro("Sign up: set address");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();
    const action = getActionService();
    const routerService = getRouterService();
    const authService = getAuthService();

    try {
      const { signupMethod } = stateMachine.state$.value || {};
      const regMode = getRegMode(signupMethod);

      const { data } = await sdk.signup.setAddress(regMode, formData).result;

      if (data.completed) {
        authService.setIsAuthenticated();
      }

      const { actionType } = action.data$.value;
      pushLogToFaro("Success: set address", { actionType });

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        stepData: data,
        // signupMethod: AuthMethod.Phone, // TODO remove hardcode - set on last step
      });
    } catch (e) {
      pushErrorToFaro(e, {
        action: "setProfile",
        message: "Error: failed to set address",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      const errorTextKey = getErrorTextFromResponse(e);

      setError && setError("root", errorTextKey);
    }
  },

  vendorIntegration: async () => {
    pushLogToFaro("Sign up: vendor integration");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();
    const routerService = getRouterService();
    const action = getActionService();
    const authService = getAuthService();

    try {
      const { signupMethod } = stateMachine.state$.value || {};
      const regMode = getRegMode(signupMethod);

      const { data } = await sdk.signup.vendorIntegration(regMode).result;

      authService.setIsAuthenticated();

      const { actionType } = action.data$.value;
      pushLogToFaro("Success: vendor integration", { actionType });

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        stepData: data,
        signupMethod: AuthMethod.Phone, // TODO remove hardcode - set on last step
      });

      return null;
    } catch (e) {
      pushErrorToFaro(e, {
        action: "vendorIntegration",
        message: "Error: vendor integration failed",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      return { isError: true, message: "vendorIntegrationError" };
    }
  },

  skipStep: async (
    step: string,
    additionalRequest?: (
      regMode: RegistrationType
    ) => Promise<IRegStepResponseAttributes>
  ) => {
    pushLogToFaro(`Sign up: skip step ${step}`);

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();
    const routerService = getRouterService();

    try {
      const { signupMethod } = stateMachine.state$.value || {};
      const regMode = getRegMode(signupMethod);

      let data: IRegStepResponseAttributes | null = null;

      ({ data } = await sdk.signup.skipStep(regMode, step).result);

      if (additionalRequest) {
        data = await additionalRequest(regMode);
      }

      if (data.completed) {
        const authService = getAuthService();
        authService.setIsAuthenticated();
      }

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        stepData: data,
      });
    } catch (e) {
      pushErrorToFaro(e, {
        message: `Error: failed to skip step ${step}`,
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  },

  skipPhoneActivationStep: async () => {
    const sdk = getSdk();

    const additionalRequest = async (regMode: RegistrationType) => {
      const { data } = await sdk.signup.regWithTerms(regMode, true).result;
      pushLogToFaro("Success: registration with terms");

      return data;
    };

    await signupService.skipStep("phoneActivation", additionalRequest);
  },

  skipProfileStep: async () => {
    const { signupMethod } = stateMachine.state$.value || {};
    const regMode = getRegMode(signupMethod);

    await signupService.skipStep("profile");

    // TODO remove this step on BE or add UI
    if (regMode === RegistrationType.google) {
      await signupService.skipStep("birth");
    }
  },

  data$: stateMachine.state$,
};
