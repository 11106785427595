import isEmpty from "lodash/isEmpty";
import { StateSubject } from "@roketus/web-toolkit";
import type { ISDK } from "@roketus/loyalty-end-user-js-sdk";
import type {
  IIssuerConfigData,
  IIssuerConfigService,
} from "../../boundary/IIssuerConfigService";
import { getSdk } from "../../diContainer/getDependencies";
import {
  CustomCodeError,
  EC_EMPTY_CONFIG,
  EC_FAILED_FETCH_CONFIG_DATA,
} from "../../domain/specs/errorCodes";
import { pushErrorToFaro } from "../../utils/faroLogs";
import { fetchConfigAPI } from "../api/fetchIssuerConfig";

const stateMachine = new StateSubject<IIssuerConfigData>({
  loading: false,
  isLoaded: false,
  failed: false,
  config: {},
  telegramBot: null,
});

const setIssuer: IIssuerConfigService["setIssuer"] = (issuerSysName, sdk) => {
  sdk = sdk || getSdk();
  sdk.setIssuer(issuerSysName);
};

const getIssuer: IIssuerConfigService["getIssuer"] = (sdk) => {
  sdk = sdk || getSdk();
  return sdk.getIssuer();
};

const getPrivacyPolicyLink: IIssuerConfigService["getPrivacyPolicyLink"] =
  () => {
    return stateMachine.state$.value.config.language === "uk"
      ? "/Privacy_policy_060824_Roketus_ukr.pdf"
      : "/Privacy_policy_060824_Roketus_eng.pdf";
  };

const getTermsLink: IIssuerConfigService["getTermsLink"] = () => {
  return stateMachine.state$.value.config.language === "uk"
    ? "/Terms_and_Conditions_Loyalty_System_UKR_230724.pdf"
    : "/Terms_and_Conditions_Loyalty_System_ENG_230724.pdf";
};

const getIssuerTelegramBot: IIssuerConfigService["getIssuerTelegramBot"] =
  async function (sdk) {
    sdk = sdk || getSdk();

    try {
      const { data } = await sdk.issuer.getIssuerTlgBot().result;

      return data.bot_name;
    } catch (e) {
      pushErrorToFaro(e, { message: "Error: failed to fetch telegram bot" });
      
      return null;
    }
  };

const fetchConfig: IIssuerConfigService["fetchConfig"] = async function (
  issuerSysName,
  sdk
) {
  stateMachine.setState({
    loading: true,
  });

  try {
    const [config, telegramBot] = await Promise.all([
      fetchConfigAPI(issuerSysName),
      getIssuerTelegramBot(sdk),
    ]);

    if (isEmpty(config)) {
      throw new CustomCodeError(EC_EMPTY_CONFIG);
    }

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      config,
      telegramBot,
    });
  } catch (e) {
    pushErrorToFaro(e, { message: "Error: failed to fetch issuer config" });

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: true,
    });

    throw new CustomCodeError(EC_FAILED_FETCH_CONFIG_DATA);
  }
};

export const init = function (sdk: ISDK): IIssuerConfigService {
  return {
    setIssuer: (issuerSysName) => setIssuer(issuerSysName, sdk),
    getIssuer: () => getIssuer(sdk),
    getPrivacyPolicyLink,
    getTermsLink,
    fetchConfig: (issuerSysName) => fetchConfig(issuerSysName, sdk),
    getIssuerTelegramBot: () => getIssuerTelegramBot(sdk),
    data$: stateMachine.state$,
  };
};
